var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-container" }, [
    _vm.loading
      ? _c(
          "div",
          [
            _vm.loading
              ? _c("v-progress-circular", {
                  staticClass: "mt-10",
                  attrs: { color: "inqliPurple", indeterminate: "" }
                })
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.content
              ? _c("content-detail", {
                  attrs: { content: _vm.content, author: _vm.author }
                })
              : _vm._e(),
            _vm.relatedQuestions &&
            !(_vm.relatedQuestions.length == 0 && _vm.totalQuestionPage == 1)
              ? _c("question-list", {
                  staticClass: "related-questions",
                  attrs: {
                    "question-list": _vm.relatedQuestions,
                    title: "Questions"
                  }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }