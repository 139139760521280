var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-list" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._l(_vm.questionList, function(question) {
        return _c("question-card", {
          key: question.question.id,
          attrs: {
            question: question.question,
            "reply-count": question.metadata.reply_count
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }