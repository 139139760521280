<template>
  <div class="content-container">
    <div v-if="loading">
      <v-progress-circular
        class="mt-10"
        v-if="loading"
        color="inqliPurple"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <content-detail v-if="content" :content="content" :author="author" />
      <question-list
        class="related-questions"
        v-if="
          relatedQuestions &&
          !(relatedQuestions.length == 0 && totalQuestionPage == 1)
        "
        :question-list="relatedQuestions"
        title="Questions"
      />
    </div>
  </div>
</template>

<script>
import sendRequest from "../app/utils/send-request-helper";
import ContentDetail from "./components/ContentDetail.vue";
import QuestionList from "./components/QuestionList.vue";

export default {
  data() {
    return {
      loading: true,
      author: {},
      content: {},
      currentQuestionPage: 1,
      totalQuestionPage: 1,
      relatedQuestions: [],
      limit: 3,
      isBottom: false,
    };
  },
  metaInfo: {
    title: "Content details",
  },
  components: {
    "content-detail": ContentDetail,
    "question-list": QuestionList,
  },
  async mounted() {
    const contentId = this.$route.params.id;
    await this.getContent(contentId);
    this.getRelatedQuestions(contentId);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async getContent(contentId) {
      try {
        const res = await sendRequest({
          url: `/resources/contents/${contentId}`,
          method: "GET",
          isAuth: false,
        });
        this.content = res.content;
        this.author = res.author;
      } catch (err) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting content details."
        );
      }
      this.loading = false;
    },
    async getRelatedQuestions(contextId) {
      try {
        const res = await sendRequest({
          url: `/resources/questions?context_id=${contextId}&limit=${this.limit}&page=${this.currentQuestionPage}`,
          method: "GET",
          isAuth: false,
        });
        this.totalQuestionPage = res.total_pages;
        this.relatedQuestions = this.relatedQuestions.concat(res.list);
      } catch (err) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting content details."
        );
      }
    },
    async handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        this.isBottom == false &&
        this.currentQuestionPage < this.totalQuestionPage
      ) {
        this.isBottom = true;
        await this.getRelatedQuestions(this.content.id);
        this.currentQuestionPage++;
        this.isBottom = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  margin: auto;
  margin-top: 16px;
  .loading {
    margin-top: 200px;
  }
  .content-details {
    text-align: left;
  }
}
.related-questions {
  margin-top: 32px;
}
</style>
