<template>
  <div class="question-list">
    <h2>{{ title }}</h2>
    <question-card
      v-for="question in questionList"
      :key="question.question.id"
      :question="question.question"
      :reply-count="question.metadata.reply_count"
    />
  </div>
</template>
<script>
import QuestionSectionCard from "../../app/components/questions/QuestionSectionCard.vue";
export default {
  name: "RelatedQuestionList",
  components: {
    "question-card": QuestionSectionCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    questionList: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.question-list {
  width: 100%;
}
</style>
